import React from "react"

const Max250 = (props) => <img {...props} alt="team member" style={{ maxHeight: "250px" }} />

const TeamPeopleList = () => (
  <section className="team_4 bg-light pt-105 pb-100 text-center">
    <div className="container px-xl-0">
      <h2 className="mb-15 small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
        Meet the team behind mix:analog
      </h2>
      <div className="row justify-content-center pt-30">
        <div className="col-xl-10 col-lg-11" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
          <div className="hr bg-gray h-2" />
        </div>
      </div>
      <div className="row pt-30 pb-50 inner">
        <div className="col-xl-2 col-lg-1" />
        <div className="col-md-1 col-sm-2">
          <i className="fas fa-quote-left f-36 color-gray" />
        </div>
        <div className="col-xl-7 col-lg-8 col-sm-10">
          <div className="pt-10 f-22 text-adaptive text-left">
            Our team is a multidisciplinary mixture of experienced software industry professionals, audio experts and electronics engineers. We are deeply passionate about music
            creation process and wish that everyone would have access to the tools to make their music sound the best possible.
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-11" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
          <div className="hr bg-gray h-2" />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="mt-70 col-xl-3 col-lg-4 col-sm-6" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
          <Max250 src={require("../../i/gh.jpg")} className={"radius6 bg-dark img-fluid"} />
          <div className="mt-20 mb-10 f-22 title">Gabrijela Hladnik</div>
          <div className="f-14 semibold text-uppercase sp-20 action-1">Co-Founder & Board Member</div>
          <div className="mt-10 color-heading text-adaptive">The helping hand behind successful projects - making sure that people are happy and results extraordinary.</div>
        </div>
        <div className="mt-70 col-lg-4 col-sm-6" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
          <Max250 src={require("../../i/bs.jpeg")} className={"radius6 bg-dark img-fluid"} />
          <div className="mt-20 mb-10 f-22 title">Bojan Šernek</div>
          <div className="f-14 semibold text-uppercase sp-20 action-1">Co-Founder & Full-Stack</div>
          <div className="mt-10 color-heading text-adaptive">
            The ideas person that thrives executing under extreme deadlines, shoestring budgets and infinite unknowns. In other words, a real startups person.
          </div>
        </div>
        <div className="mt-70 col-xl-3 col-lg-4 col-sm-6" data-aos-duration="600" data-aos="fade-down" data-aos-delay="600">
          <Max250 src={require("../../i/zr.jpg")} className={"radius6 bg-dark img-fluid"} />
          <div className="mt-20 mb-10 f-22 title">Žiga Rezar</div>
          <div className="f-14 semibold text-uppercase sp-20 action-1">Everything</div>
          <div className="mt-10 color-heading text-adaptive">
            Tirelessly exploring the limits of sound and pushing boundaries of what can be expressed with it. Resident coffee nerd.
          </div>
        </div>
        <div className="mt-70 col-xl-3 col-lg-4 col-sm-6" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
          <Max250 src={require("../../i/es.jpg")} className={"radius6 bg-dark img-fluid"} />
          <div className="mt-20 mb-10 f-22 title">Elvis Špehar</div>
          <div className="f-14 semibold text-uppercase sp-20 action-1">Hardware, Maintenance</div>
          <div className="mt-10 color-heading text-adaptive">
            The electronics guy that electronics guys go to when they are stuck. An ohms and amps wizard who solders SMDs by hand for breakfast.
          </div>
        </div>
        <div className="mt-70 col-lg-4 col-sm-6" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
          <Max250 src={require("../../i/mh.jpeg")} className={"radius6 bg-dark img-fluid"} />
          <div className="mt-20 mb-10 f-22 title">Martin Hribar</div>
          <div className="f-14 semibold text-uppercase sp-20 action-1">Full-Stack, Ops</div>
          <div className="mt-10 color-heading text-adaptive">
            Self-taught in administration, full-stack development and dev ops. Strongly believes everything is better with nine or more chili peppers.
          </div>
        </div>
        <div className="mt-70 col-xl-3 col-lg-4 col-sm-6" data-aos-duration="600" data-aos="fade-down" data-aos-delay="600">
          <Max250 src={require("../../i/mgp.jpg")} className={"radius6 bg-dark img-fluid"} />
          <div className="mt-20 mb-10 f-22 title">Matej Gal Pintar</div>
          <div className="f-14 semibold text-uppercase sp-20 action-1">Business Coach</div>
          <div className="mt-10 color-heading text-adaptive">
            Vast experience in coaching management and leadership from non profits to government enterprises. Avid car collector.
          </div>
        </div>
      </div>
      <div data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
        <a href="mailto:info@mixanalog.com" className="mt-60 btn size50 action-1">
          Start working with us
        </a>
      </div>
    </div>
  </section>
)

export default TeamPeopleList
