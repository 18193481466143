import React from "react"
import { graphql } from "gatsby"

import LayoutPage from "../components/layout-page"
import TeamPeopleList from "../components/team/team-list"
import TeamHero from "../components/team/team-hero"
import OurStory from "../components/team/our-story"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"

const TeamPage = ({ data: { bg } }) => (
  <LayoutPage>
    <SEO title={"Team"} meta={ImageMeta}/>
    <TeamPeopleList />
    <TeamHero bg={bg.childImageSharp.fluid} />
    <OurStory />
  </LayoutPage>
)

export default TeamPage

export const query = graphql`
  query {
    bg: file(relativePath: { eq: "team-office.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 8192, base64Width: 446) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
