import React from "react"

class OurStory extends React.Component {
  state = { videoOpen: false }

  openVideo = e => {
    if (e) {
      e.preventDefault()
    }

    this.setState({ videoOpen: true })
  }

  closeVideo = () => {
    this.setState({ videoOpen: false })
  }

  render() {
    return (
      <section className="content_19 bg-light pt-105 pb-90" id={"story"}>
        <div className="container px-xl-0">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-11">
              <h2 className="small text-left text-lg-center" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
                The Story So Far
              </h2>
              <div className="row justify-content-center justify-content-lg-between">
                <div className="col-lg-6 mt-45 block" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
                  <div className="f-heading medium f-18 title">2012: In the beginning</div>
                  <div className="mt-10 color-heading text-adaptive">
                    <p>
                      Our founders Bojan and Gabrijela used to operate the H&H recording studio in Ljubljana. We really liked working with bands and handling multiple projects, but
                      what they did not expect was the chasm between the sound of analog outboard equipment and the usability of software plugins.
                    </p>
                    <p>
                      Having to choose between sound quality and fast recall, they decided to do something about it and founded a <b>Distopik</b>, the Audio R&D company.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 mt-45 block" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
                  <div className="f-heading medium f-18 title">2014: A few years later</div>
                  <div className="mt-10 color-heading text-adaptive">
                    <p>
                      It seemed like a simple enough project at the start. For example, add electrical motors to the machines, make them talk to a computer and you could have a
                      recall solution.
                    </p>
                    <p>
                      We started that way, but didn't like the slow speed, low accuracy and mechanical maintenance that came with that. So we called in Elvis as THE electronics
                      expert to sort out a way to do the digital control electronically.
                    </p>
                    <p>
                      With his help, <a href={"/technology"}>we succeeded</a>!
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 mt-45 block" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
                  <div className="f-heading medium f-18 title">2015: Expanding the team and shaping products</div>
                  <div className="mt-10 color-heading text-adaptive">
                    <p>
                      The first prototypes were very well received by the legendary studio RSL, the largest private recording facility in our part of Europe. This gave us the
                      needed courage to expand the team and start working on new products.
                    </p>
                    <p>
                      Our automation was certainly very good: it sounded completely neutral and <a href={"/technology"}>measured invisible</a>. However, it was also very expensive
                      to manufacture.
                    </p>
                    <p>
                      We did not want to compromise on quality, so we instead thought how more than one machine could be used by more than one user. That way the cost per user
                      would go down and we could continue to make the highest quality automation!
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 mt-45 block" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
                  <div className="f-heading medium f-18 title">2017: Launch 1.0!</div>
                  <div className="mt-10 color-heading text-adaptive">
                    <p>
                      Our answer was to use the power of the internet, open up machines to the public with a simple booking interface and thus offer both the automation and the
                      outboard sound to thousands of users worldwide.
                    </p>
                    <p>
                      Sure, only one user could use a certain machine at the same time but even with this limitation, mix:analog proved to be a big success, with over 10,000 users
                      signing up since its inception.
                    </p>
                    <p>
                      The combination of easy booking process, great sound and complete privacy makes mix:analog one of the best newcomers to the pro audio scene and a favourite
                      secret weapon for those who are adventurous enough to give it a go.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 mt-45 block" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
                  <div className="f-heading medium f-18 title">2018: More gear and partnerships</div>
                  <div className="mt-10 color-heading text-adaptive">
                    <p>
                      Encouraged by very positive reviews and encouragement from the industry, we decided to work with audio gear manufacturers directly. We enable them with the
                      perfect opportunity for online demos and give them the ability to deliver their sound and aesthetics to producers all around the world.
                    </p>
                    <p>
                      It also became very apparent that while the technology side was starting to take shape and we could handle software and hardware development, we needed more
                      guidance on business.
                    </p>
                    <p>In 2018 we joined the Danish startup accelerator Accelerace and started working with coaches Kristijan Pečanec and Matej Gal Pintar.</p>
                  </div>
                </div>
                <div className="col-lg-6 mt-45 block" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
                  <div className="f-heading medium f-18 title">
                    2019: Tech iteration and <span className={"text-monospace"}>audiocloud.io</span>
                  </div>
                  <div className="mt-10 color-heading text-adaptive">
                    <p>Watch this space... something big will drop here soon.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default OurStory
