import React from "react"
import BackgroundImage from "gatsby-background-image"

const TeamHero = ({ bg }) => (
  <BackgroundImage className="pt-580 pb-160 color-white" Tag={"section"} fluid={bg}>
    <div className="container px-xl-0 p-5 bg-team-hero">
      <div className="row">
        <div className="col-lg-1" />
        <div className="col-lg-7 col-md-10">
          <h2 data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
            Our second home
          </h2>
          <div className="mt-15 f-22 text-adaptive" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
            Located in the center of Ljubljana, our workplace is hosted by{" "}
            <a href={"http://hekovnik.com"} rel="noopener noreferrer" target={"_blank"}>
              Hekovnik
            </a>
            , the premiere startup school in the area. We are lucky to find ourselves immersed in a culture of understanding, growth and innovation.
          </div>
        </div>
      </div>
    </div>
  </BackgroundImage>
)

export default TeamHero
